import React from 'react';
import { injectIntl } from 'react-intl'

function Copyright({intl}) {
    const date = new Date();
    const currentYear = date.getFullYear();
    return (
        <div className="copy">{`© ${currentYear} ${intl.messages.copyright_text}`}</div>
    );
}

export default injectIntl(Copyright);



