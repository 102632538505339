import ActionType from "../../constants/ActionTypes";

const {
    GET_OPENGRAPH_RULES_BEGIN,
    GET_OPENGRAPH_RULES_ERROR,
    GET_OPENGRAPH_RULES_SUCCESS,
    DELETE_OPENGRAPH_RULES_BEGIN,
    DELETE_OPENGRAPH_RULES_ERROR,
    DELETE_OPENGRAPH_RULES_SUCCESS,
    EDIT_OPENGRAPH_RULE_BEGIN,
    EDIT_OPENGRAPH_RULE_ERROR,
    EDIT_OPENGRAPH_RULE_SUCCESS,

} = ActionType;

const initialState = {
    data: null,
    loading: true,
    error: null,
    isDeleting: false,
    isSaving: false
};

const openGraphReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_OPENGRAPH_RULES_BEGIN:
            return {
                ...state,
                loading: true
            };
        case GET_OPENGRAPH_RULES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case GET_OPENGRAPH_RULES_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_OPENGRAPH_RULES_BEGIN:
            return {
                ...state,
                isDeleting: true
            };
        case DELETE_OPENGRAPH_RULES_ERROR:
        case DELETE_OPENGRAPH_RULES_SUCCESS:
            return {
                ...state,
                isDeleting: false
            };
        case EDIT_OPENGRAPH_RULE_BEGIN:
            return {
                ...state,
                isSaving: true
            };
            case EDIT_OPENGRAPH_RULE_ERROR:
        case EDIT_OPENGRAPH_RULE_SUCCESS:
            return {
                ...state,
                isSaving: false
            };

        default:
            return state;
    }
};

export default openGraphReducer;