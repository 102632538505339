import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { intlReducer } from 'react-intl-redux'
import userReducer from './userReducer';
import projectsReducer from './project/projectsReducer';
import templateReducer from './project/templateReducer';

// settings reducers
import addOnsReducer from './settings/current/addOnsReducer';
import captchaReducer from './settings/current/captchaReducer';
import languagesReducer from './settings/current/languagesReducer';
import currenciesReducer from './settings/current/currenciesReducer';
import productsReducer from './settings/current/productsReducer';
import paymentsReducer from './settings/current/paymentsReducer';
import generalReducer from './settings/current/generalReducer';
import domainsReducer from './settings/current/domainsReducer';
import overviewReducer from './settings/current/overviewReducer';
import logosReducer from './settings/current/logosReducer';
import thirdPartyIntegrationsReducer from './settings/current/thirdPartyIntegrationsReducer';
import customCodeReducer from './settings/current/customCodeReducer';
import bannersReducer from './settings/current/bannersReducer';
import seoReducer from './settings/current/seoReducer';
import licensesReducer from './settings/current/licensesReducer';
import uiControlsReducer from './ui/uiControlsReducer';
import businessRulesReducer from './settings/current/businessRulesReducer';
import devToolsRedusers from './settings/current/devToolsRedusers';

// UI reducers
import socialMediaReducer from './ui/socialMediaReducer';
import paymentControlReducer from './ui/paymentControlReducer';
import deviceModeReducer from './ui/deviceModeReducer';
import featuredProductsReducer from './ui/featuredProductsReducer';
import iconSetReducer from './ui/iconSetReducer';
import languageBarReducer from './ui/languageBarReducer';
import accessButtonsReducer from './ui/accessButtonsReducer';
import pagesReducer from './ui/pagesReducer';
import backgroundImagesReducer from './ui/backgroundImagesReducer';
import announcmentReducer from './ui/announcmentReducer';
import mobileAppReducer from './ui/mobileAppReducer';

import uiSettingsReducer from './ui/uiSettingsReducer';
import templatesReducer from './ui/templatesReducer';
import controlsReducer from './ui/controlsReducer';
import timerReducer from './ui/timerReducer';
import providersReducer from './ui/providersReducer';
import promotionsReducer from './ui/promotionsReducer';
import allSettingsReducer from './settings/all/allSettingsReducer';
import verificationReducer from './account/verificationReducer';

import roleReducer from './account/roleReducer'
import userManagementReducer from './account/userManagementReducer'
import imageBlocksReducer from './settings/current/imageBlocksReducer';
import storyBlocksReducer from './settings/current/storyBlocksReducer';
import appAdviserReducer from './settings/current/appAdviserReducer';
import widgetReducer from './ui/widgetReducer';
import popupsReducer from './ui/popupsReducer';
import SEORulesReducer from './ui/SEORulesReducer';
import mobileAppsReducer from './settings/current/mobileAppsReducer';
import requestsReducer from './account/requestsReducer';
import openGraphReducer from './ui/OpenGraphReducer';
 

export default (history) => combineReducers({
  router: connectRouter(history),
  intl: intlReducer,
  user: userReducer,
  verification: verificationReducer,
  builder: combineReducers({
    projects: projectsReducer,
    templates: templateReducer,
    settings: combineReducers({
      allSettings: allSettingsReducer,
      current: combineReducers({
        overview: overviewReducer,
        addOns: addOnsReducer,
        captcha: captchaReducer,
        languages: languagesReducer,
        licenses: licensesReducer,
        currencies: currenciesReducer,
        products: productsReducer,
        externalServices: thirdPartyIntegrationsReducer,
        customCodes: customCodeReducer,
        payments: paymentsReducer,
        general: generalReducer,
        domains: domainsReducer,
        logos: logosReducer,
        banners: bannersReducer,
        banners: bannersReducer,
        imageBlocks: imageBlocksReducer,
        storyBlocks: storyBlocksReducer,
        seo: seoReducer,
        businessRules:businessRulesReducer,
        devTools:devToolsRedusers,
        appAdviser:appAdviserReducer,
        mobileApps:mobileAppsReducer,
      })
    }),
    ui: combineReducers({
      deviceMode: deviceModeReducer,
      socialMedia: socialMediaReducer,
      settings: uiSettingsReducer,
      template: templatesReducer,
      controls: controlsReducer,
      timerControl: timerReducer,
      featuredProducts: featuredProductsReducer,
      uiControls: uiControlsReducer,
      paymentControl: paymentControlReducer,
      providers: providersReducer,
      iconSet: iconSetReducer,
      promotions: promotionsReducer,
      languageBar: languageBarReducer,
      accessButtons: accessButtonsReducer,
      pages: pagesReducer,
      popups: popupsReducer,
      backgroundImages: backgroundImagesReducer,
      widget: widgetReducer,
      announcments: announcmentReducer,
      mobileApp:mobileAppReducer,
      SEORules:SEORulesReducer,
      openGraphRules:openGraphReducer
    }),
    account: combineReducers({
      roles: roleReducer,
      users: userManagementReducer,
      requests: requestsReducer
    })
  })
});