//import react polyfill
import React from 'react';
import { render } from 'react-dom';

import config from './constants/Configs'

//Import App Components
import './index.css';
import App from './App';
import './services/AxiosMiddleware';

// import registerServiceWorker from './registerServiceWorker';

// Import ant less file and override variables
import "./assets/less/ant.css";

//Import styles
import './assets/styles/main.css';
// import './assets/font-awesome/css/font-awesome.min.css';

import { IntlProvider } from 'react-intl-redux'
// import { addLocaleData } from 'react-intl'
import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router';
import configureStore from './services/ConfigureStore'
import history from './services/History';


//Initial state
import { initialState } from './constants/InitialState';
// import ruLocaleData from 'react-intl/locale-data/ru'
// import enLocaleData from 'react-intl/locale-data/en'
// import trLocaleData from 'react-intl/locale-data/tr'
// import esLocaleData from 'react-intl/locale-data/es'
// import roLocaleData from 'react-intl/locale-data/ro'

var localesMyAppSupports = [
  /* list locales here */
];
if (global.Intl) {
  global.Intl = require('intl');
}


// addLocaleData([...enLocaleData, ...ruLocaleData, ...trLocaleData, ...esLocaleData, ...roLocaleData])

const store = configureStore(initialState)

store.subscribe(() => {
  //localStorage.setItem('saved_store_state', JSON.stringify(store.getState()));
});
export default store;

const renderApp = () => {
  const target = document.querySelector('#root');
  render(<Provider store={store}>
    <IntlProvider>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </IntlProvider>
  </Provider>,
    target
  );
}
// IF SSO IS NOT ENABLED CASE
if (!config.IS_SSO_ENABLED) {
  renderApp();
}
// IF SSO IS ENABLED CASE
else {
  let isLoading = true;
  const platLoginURL = config.PLATH_LOGIN_URL;
  renderApp();


  function recieveMsg(message) {
    if (window.location.pathname === "/login") {
      console.log('/login')
      window.location.href = platLoginURL;
    }

    if (message && message.data && typeof message.data === "string") {
      const data = JSON.parse(message.data);
      console.log(data)
      if (data.method === "remove") {
        window.location.href = platLoginURL;
      } else if (data.method === "add") {
        localStorage.setItem(data.key, JSON.stringify(data.data));
        isLoading = false;
        renderApp();
      }
    }
  }

  window.addEventListener("message", recieveMsg);

  //Add auth iframe to the page
  const iframe = document.createElement('iframe');
  iframe.style.height = '1px';
  iframe.style.width = '1px';
  iframe.style.position = 'absolute';
  iframe.style.top = 0;
  iframe.style.border = 0;
  iframe.id = "account-frame";
  iframe.src = config.AUTH_IFRAME_URL;
  document.body.appendChild(iframe);

  setTimeout(function () {
    if (isLoading) {
      console.log('20000')
      window.location.href = platLoginURL;
    }
  }, 20000);
}

// registerServiceWorker();
